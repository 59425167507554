import React, { useEffect, useRef, useState } from "react";
import milaFirmware from "assets/images/mila-firmware.png";
import milaApp from "assets/images/mila-app.png";
import milaManual from "assets/images/mila-manual.png";
import loadable from "@loadable/component";
import generateKey from "../../../shared/utils/generateKey";
import {
  firmwareChangeLogStatic,
  appChangeLogStatic,
  airAppChangeLogStatic,
  airMiniAppChangeLogStatic,
} from "../../static-data";

const LazyImg = loadable(() => import("../common/LazyImg"));

/**
 * @param version
 * @param description
 * @param time
 * @param prettyTime
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeLogCard = ({ version, description, time, prettyTime }) => (
  <div className="change-log__card">
    <div className="change-log__card-content">
      <p className="change-log__card-version">{version}</p>
      <div className="change-log__card-description">{description}</div>
    </div>
    {time && prettyTime && (
      <time className="change-log__card-release" dateTime={time}>
        {prettyTime}
      </time>
    )}
  </div>
);

/**
 * @returns {JSX.Element}
 * @constructor
 */
const DownloadBlock = () => {
  const [currentIndex, setCurrentIndex] = useState(null);

  const bodyRefs = useRef([]).current;

  useEffect(() => {
    bodyRefs.forEach((ref, index) => {
      const getOuterHeight =
        index === currentIndex ? bodyRefs[index].scrollHeight : 0;
      bodyRefs[index].style.maxHeight = `${getOuterHeight}px`;

      window.addEventListener("resize", () => {
        if (index === currentIndex) {
          bodyRefs[index].style.maxHeight = `${bodyRefs[index].scrollHeight}px`;
        } else {
          bodyRefs[index].style.maxHeight = "0px";
        }
      });
    });
  }, [currentIndex]);

  const accordionToggle = (e, index) => {
    e.preventDefault();
    setCurrentIndex(index === currentIndex ? null : index);
  };

  return (
    <section id="other" className="download-block support-block">
      <div className="container">
        <ul className="download-block__list">
          {/* <li className="download-block__list-item">
            <figure className="download-block__image">
              <LazyImg src={milaFirmware} alt="Mila firmware" />
            </figure>
            <div className="download-block__content">
              <h2 className="download-block__title">
                Mila Firmware - Current Version
              </h2>
              <div className="download-block__description">
                <p>
                  Automatically receive new firmware updates when your Mila unit
                  is online.
                </p>
              </div>
              <p className="download-block__version">
                v<strong>{firmwareChangeLogStatic.at(0).version}</strong>
              </p>
              <div className="change-log">
                <a
                  href="#"
                  key={generateKey("change-log__toggle")}
                  className={`change-log__toggle ${
                    currentIndex === 1 ? "change-log__toggle--is-active" : ""
                  }`}
                  onClick={(e) => accordionToggle(e, 1)}
                >
                  Change log
                  <svg className="icon icon-down-arrow-alt">
                    <use xlinkHref="#icon-down-arrow-alt" />
                  </svg>
                </a>
                <div
                  className="change-log__body"
                  key={generateKey("change-log__body")}
                  aria-hidden={currentIndex !== 1}
                  ref={(el) => (bodyRefs[1] = el)}
                >
                  <div className="change-log__body-inner">
                    {firmwareChangeLogStatic.map((card) => (
                      <ChangeLogCard
                      key={generateKey("change-log__card")}
                      {...card}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </li> */}
          <li className="download-block__list-item download-block__list-item--mila-app">
            <figure className="download-block__image">
              <LazyImg src={milaApp} alt="Mila App" />
            </figure>
            <div className="download-block__content">
              <h2 className="download-block__title">
                Mila App - Current Version
              </h2>
              <div className="download-block__description">
                <p>
                  Stay updated on the latest app versions to make the most of
                  your Mila.
                </p>
              </div>
              <p className="download-block__version">
                v<strong>{appChangeLogStatic.at(0).version}</strong>
              </p>
              <a href="#app-installation" className="btn btn--outline">
                Download latest version
              </a>
              <div className="change-log">
                <a
                  href="#"
                  key={generateKey("change-log__toggle")}
                  className={`change-log__toggle ${
                    currentIndex === 2 ? "change-log__toggle--is-active" : ""
                  }`}
                  onClick={(e) => accordionToggle(e, 2)}
                >
                  Change log
                  <svg className="icon icon-down-arrow-alt">
                    <use xlinkHref="#icon-down-arrow-alt" />
                  </svg>
                </a>
                <div
                  key={generateKey("change-log__body")}
                  className="change-log__body"
                  aria-hidden={currentIndex !== 2}
                  ref={(el) => (bodyRefs[2] = el)}
                >
                  <div className="change-log__body-inner">
                    {appChangeLogStatic.map((card) => (
                      <ChangeLogCard
                        key={generateKey("change-log__card")}
                        {...card}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="download-block__list-item">
            <h3 className="download-block__heading">Mila Air </h3>
            <figure className="download-block__image">
              <LazyImg src={milaFirmware} alt="Mila firmware" />
            </figure>
            <div className="download-block__content">
              <h2 className="download-block__title">
                Mila Air Firmware - Current Version
              </h2>
              <div className="download-block__description">
                <p>
                  Automatically receive new firmware updates when your Mila Air
                  unit is online.
                </p>
              </div>
              <p className="download-block__version">
                v<strong>{airAppChangeLogStatic.at(0).version}</strong>
              </p>
              <div className="change-log">
                <a
                  href="#"
                  key={generateKey("change-log__toggle")}
                  className={`change-log__toggle ${
                    currentIndex === 3 ? "change-log__toggle--is-active" : ""
                  }`}
                  onClick={(e) => accordionToggle(e, 3)}
                >
                  Change log
                  <svg className="icon icon-down-arrow-alt">
                    <use xlinkHref="#icon-down-arrow-alt" />
                  </svg>
                </a>
                <div
                  className="change-log__body"
                  key={generateKey("change-log__body")}
                  aria-hidden={currentIndex !== 3}
                  ref={(el) => (bodyRefs[3] = el)}
                >
                  <div className="change-log__body-inner">
                    {airAppChangeLogStatic.map((card) => (
                      <ChangeLogCard
                      key={generateKey("change-log__card")}
                      {...card}
                    />
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="download-block__list-item">
            <figure className="download-block__image">
              <LazyImg src={milaManual} alt="Mila Manual" />
            </figure>
            <div className="download-block__content">
              <h2 className="download-block__title">User Manual</h2>
              <div className="download-block__description">
                <p>
                  Questions about your Mila unit? Get the answers you need in
                  this handy manual.
                </p>
              </div>
              <a
                target="_blank"
                href="https://docs.milacares.com/Mila_User_Manual.pdf"
                className="btn btn--outline btn--valign-center"
                rel="noreferrer"
              >
                Download the manual
              </a>
            </div>
          </li>

          <li className="download-block__list-item">
            <h3 className="download-block__heading">Mila Air Mini </h3>
            <figure className="download-block__image">
              <LazyImg src={milaFirmware} alt="Mila firmware" />
            </figure>
            <div className="download-block__content">
              <h2 className="download-block__title">
              Mila Air Mini Firmware - Current Version
              </h2>
              <div className="download-block__description">
                <p>
                  Automatically receive new firmware updates when your Mila Air
                  unit is online.
                </p>
              </div>
              <p className="download-block__version">
                v<strong>{airMiniAppChangeLogStatic.at(0).version}</strong>
              </p>
              <div className="change-log">
                <a
                  href="#"
                  key={generateKey("change-log__toggle")}
                  className={`change-log__toggle ${
                    currentIndex === 4 ? "change-log__toggle--is-active" : ""
                  }`}
                  onClick={(e) => accordionToggle(e, 4)}
                >
                  Change log
                  <svg className="icon icon-down-arrow-alt">
                    <use xlinkHref="#icon-down-arrow-alt" />
                  </svg>
                </a>
                <div
                  className="change-log__body"
                  key={generateKey("change-log__body")}
                  aria-hidden={currentIndex !== 4}
                  ref={(el) => (bodyRefs[4] = el)}
                >
                  <div className="change-log__body-inner">
                    {airMiniAppChangeLogStatic.map((card) => (
                      <ChangeLogCard
                      key={generateKey("change-log__card")}
                      {...card}
                    />
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="download-block__list-item">
            <figure className="download-block__image">
              <LazyImg src={milaManual} alt="Mila Manual" />
            </figure>
            <div className="download-block__content">
              <h2 className="download-block__title">User Manual</h2>
              <div className="download-block__description">
                <p>
                  Questions about your Mila unit? Get the answers you need in
                  this handy manual.
                </p>
              </div>
              <a
                target="_blank"
                href="https://docs.milacares.com/Mila_User_Manual.pdf"
                className="btn btn--outline btn--valign-center"
                rel="noreferrer"
              >
                Download the manual
              </a>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default DownloadBlock;
